import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/abouts",
    name: "abouts",
    component: () => import("../views/AboutView.vue"),
  },

  {
    path: "/about",
    name: "About",
    meta: {
      title: "关于我们",
    },
    component: () => import("../views/children/About.vue"),
  },
  {
    path: "/address",
    name: "Address",
    meta: {
      title: "联系我们",
    },
    component: () => import("../views/children/Address.vue"),
  },
  {
    path: "/case",
    name: "Case",
    meta: {
      title: "项目案例",
    },
    component: () => import("../views/children/Case.vue"),
  },
  {
    path: "/culture",
    name: "Culture",
    meta: {
      title: "企业文化",
    },
    component: () => import("../views/children/Culture.vue"),
  },
  {
    path: "/team",
    name: "Team",
    meta: {
      title: "团队介绍",
    },
    component: () => import("../views/children/Team.vue"),
  },
  {
    path: "/case",
    name: "Case",
    meta: {
      title: "项目案例",
    },
    component: () => import("../views/children/Case.vue"),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    //跳转页面回到页面首页
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
